import "lazysizes"
import "prismjs/themes/prism-solarizedlight.css"
import "./src/styles/ReactToastify.css"
import "./src/styles/landing-page.css"
import { getTrackingId } from "./src/utils/getTrackingId"
import { initializeHotjar } from "./src/utils/initializeHotjar"

function saveAquisitionPage() {
  const AQUISITION_PAGE_KEY = "aquisition_page"
  const path = window.location.pathname
  const savedAquisitionPage = sessionStorage.getItem(AQUISITION_PAGE_KEY)

  if (savedAquisitionPage) return

  sessionStorage.setItem(AQUISITION_PAGE_KEY, path)
}


const appHeight = () => {
  if (typeof document !== 'undefined') {
    const doc = document.documentElement
    doc.style.setProperty("--app-height", `${window.innerHeight}px`)
  }
}

saveAquisitionPage()

window.addEventListener("resize", appHeight)

appHeight()

export const onRouteUpdate = () => {
  function onConversationsAPIReady() {
    const widget = window.HubSpotConversations.widget
    widget.status().loaded ? widget.refresh() : widget.load()
  }

  if (window.HubSpotConversations) {
    onConversationsAPIReady()
  } else {
    window.hsConversationsOnReady = [onConversationsAPIReady]
  }
}

export const onServiceWorkerUpdateReady = () => {
  window.dispatchEvent(new CustomEvent("newVersion"))
}

export const onClientEntry = () => {
  const trackingId = getTrackingId()
  initializeHotjar(trackingId)
}
